import { Search } from '@element-plus/icons-vue';
import BaseOption from '../../../common/base-option-dialog.vue';
export default {
  components: {
    Search,
    BaseOption
  },
  data() {
    return {
      table: {},
      mapTable: {},
      pagination: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      form: {},
      mapId: 0,
      deleteId: 0,
      keyword: '',
      viewMapVisible: false,
      createMapVisible: false,
      deleteMapVisible: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入'
        }, {
          pattern: /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/,
          message: '只允许中英文，数字，下划线，中划线'
        }, {
          min: 1,
          max: 30,
          message: '最多只允许输入30位'
        }]
      }
    };
  },
  watch: {
    keyword(val) {
      if (val === '') {
        this.clearAll();
      }
    }
  },
  mounted() {
    this.getLogisticSupplier();
  },
  methods: {
    async getLogisticSupplier() {
      try {
        await this.$store.dispatch('system/base/getLogisticSupplier', {
          params: {
            current_page: this.pagination.current_page,
            page_size: this.pagination.page_size,
            name_en: this.keyword
          }
        });
        let logisticSupplier = this.$store.state.system.base;
        this.table = {
          tableData: logisticSupplier.logisticSupplier,
          tableFields: this.$global.LogisticSupplierTableFields
        };
        this.total = logisticSupplier.listLength;
      } catch (err) {
        return;
      }
    },
    queryList() {
      this.pagination.current_page = 1;
      this.getLogisticSupplier();
    },
    clearAll() {
      this.pagination = {
        current_page: 1,
        page_size: 10
      };
      this.getLogisticSupplier();
    },
    changePagination(val) {
      this.pagination = val;
      this.getLogisticSupplier();
    },
    async viewMap(id) {
      this.mapId = id;
      try {
        await this.$store.dispatch('system/base/getMap', {
          params: {
            id
          }
        });
        this.mapTable = {
          tableData: this.$store.state.system.base.mapList,
          tableFields: [{
            label: 'ID',
            prop: 'id',
            show: true,
            width: '90px'
          }, {
            label: '物流商映射',
            prop: 'name',
            show: true
          }]
        };
        this.viewMapVisible = true;
      } catch (err) {
        return;
      }
    },
    closeViewDialog() {
      this.viewMapVisible = false;
    },
    showCreateMap(id) {
      this.mapId = id;
      this.createMapVisible = true;
    },
    closeCreateDialog() {
      this.createMapVisible = false;
      this.$refs.form.resetFields();
    },
    async submitMap() {
      try {
        await this.$store.dispatch('system/base/createMap', {
          logistic_supplier_id: this.mapId,
          name: this.form.name
        });
        this.createMapVisible = false;
        this.$refs.form.resetFields();
      } catch (err) {
        return;
      }
    },
    async createMap() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitMap();
        }
      });
    },
    showConfirmDialog(id) {
      this.deleteId = id;
      this.deleteMapVisible = true;
    },
    closeDeleteMapDialog() {
      this.deleteMapVisible = false;
    },
    async deleteMap() {
      try {
        await this.$store.dispatch('system/base/deleteMap', {
          id: this.deleteId
        });
        this.deleteMapVisible = false;
        this.viewMap(this.mapId);
      } catch (err) {
        return;
      }
    }
  }
};